import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import "./header.css"

const Header = () => {
  const [mobileNav, toggleMobileNav] = useState(false);
  return (
    <nav className="navigation">
      <div className={`hamburger ${mobileNav ? 'is-active' : ''}`} id="hamburger" onClick={() => toggleMobileNav(mobileNav => !mobileNav)}>
          <span class="line"></span>
          <span class="line"></span>
          <span class="line"></span>
      </div>
      <div className="navigation__wrapper row row-xs--between row-xs--middle">
        <div className="navigation__logo-wrapper">
          <Link to="/" className="navigation__logo-anchor">
            Benjamin D. Clauss, DDS.
          </Link>
        </div>
        <ul className={`navigation__list row row-xs--middle navigation__mobile ${mobileNav ? '' : 'hidden'}`}>
          <li className="navigation__item nav-links"><Link to="/meet-dr-clauss" >Meet Dr. Clauss</Link></li>
          <li className="navigation__item nav-links"><Link to="/treatment-solutions" >Treatment Solutions</Link></li>
          <li className="navigation__item nav-links"><Link to="/financial" >Financial</Link></li>          
          <li className="navigation__item">
            <a href="tel:7166623678" className="navigation__btn-pill">
              (716) 662-3678
            </a>
          </li>
         </ul>
      </div>
    </nav>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
