import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function NotFound() {
  if (typeof window !== 'undefined') {
    window.location = '/';
  }

  return null;
}
