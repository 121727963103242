import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import "./footer.css"

const Footer = () => {
  return (
    <footer>
      <div className="footer-nav">
        <div>
          <h5>Contact</h5>
          <address>
            4314 S. Buffalo St. <br />
            Orchard Park, NY 14127
            <br />
            Phone: <a href="tel:7166623678">(716) 662-3678</a>
          </address>
          <br/>
          <strong><a className="directions-link" href="https://goo.gl/maps/cPt4NztppNJtFQqZ6">Directions</a></strong>
        </div>
        <div>
          <h5>Office Hours</h5>
          <div>Monday: 8am - 5pm</div>
          <div>Tuesday: 8am - 5pm</div>
          <div>Thursday: 8am - 5pm</div>
        </div>
        <div>
          <h5>Schedule an Appointment</h5>
          <p>
            Please call our office at{" "}
            <a href="tel:7166623678">(716) 662-3678</a>
            <br />
            to set up an appointment or <br />
            schedule a consultation with Dr. Clauss.
          </p>
        </div>
      </div>

      <div className="copyright-bar">
        <p>Copyright  ©{new Date().getFullYear()} Benjamin D. Clauss DDS </p>
      </div>
    </footer>
  )
}

export default Footer
